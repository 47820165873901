import { useState } from "react";
import { useForm } from "react-hook-form";
import Typography from "../../../../base-components/Typography";
import Input from "../../../../base-components/Input";
import RadioButton from "../../../../base-components/RadioButton";
import Button from "../../../../base-components/Button";
import Modal from "../../../../base-components/Modal";
import AlertModal from "../../../../base-components/AlertModal";
import InvoiceService from "../../../../services/InvoiceService";
import { formatNumber, formatMobile } from "../../../../utils/helper";
import Payment from "../payment";

function StepTwo(props) {
  const { currentStep, setCurrentStep, invoiceInfo } = props;
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      email: '',
      mobile: '',
    }
  });

  const [isIndividual, setisIndividual] = useState('yes');
  const [isOpenModal, setIsOpenModal] = useState(false);

  const getValue = () => {
    if (invoiceInfo?.object?.trailer)
      return "Чиргүүлтэй";
    else
      return "Чиргүүлгүй";
  }
  const onSubmit = (data) => {
    // console.log(data, "data");
    data.policy_id = invoiceInfo?.policy_id;
    // let payload = {
    //   "policy_id": invoiceInfo?.policy_id,
    //   "email": data.email,
    //   "mobile": data.mobile,
    //   "registrationNumber": data.registrationNumber,
    // }

    // хувь хүн бол зөвхөн дугаар авна байгууллага бол регистер авна
    if(isIndividual === "yes")
      delete data.registrationNumber
    else
      delete data.mobile

    let config = {
      baseURL: `https://api.agula.mn`
    }
    InvoiceService.insertOwnerInfo(data, config)
      .then((response) => {
        console.log(response, "insertOwnerInfo response!");
      })
      .catch((error) => {
        console.log(error, "error owner info");
      })
    // setCurrentStep(currentStep + 1)
    setIsOpenModal(true);
  }

  return (
    <div className="p-7 w-[700px]">
      <Typography variant="Title" size="md">Даатгуулагчийн мэдээлэл</Typography>
      <div className="mt-4 grid grid-cols-2 max-[500px]:grid-cols-1 gap-4">
        <Input
          id="insured-name"
          type="text"
          label="Даатгуулагч"
          defaultValue={invoiceInfo?.insured?.name}
        />
        <Input
          id="insured-registry"
          label="Регистерийн дугаар"
          defaultValue={invoiceInfo?.insured?.registry_number}
        />
        <Input
          id="insured-last-name"
          label="Овог"
          defaultValue="Даатгал"
        />
      </div>
      <Typography variant="Title" size="md" className="mt-6">Нэхэмжлэлийн мэдээлэл</Typography>
      <div className="mt-4 border border-stroke-dark-12 rounded-xl text-primary">
        <div className="grid grid-cols-3 max-[500px]:grid-cols-1 border-b border-stroke-dark-12 bg-surface-gray rounded-t-xl text-base">
          <div className="py-4 text-center min-[501px]:border-r max-[500px]:border-b border-stroke-dark-12">
            <span>{invoiceInfo?.object?.make}, {invoiceInfo?.object?.model}</span>
          </div>
          <div className="py-4 text-center min-[501px]:border-r max-[500px]:border-b border-stroke-dark-12">
            <span>{invoiceInfo?.object?.plate_number}</span>
          </div>
          <div className="py-4 text-center border-stroke-dark-12">
            <span>{getValue()}</span>
          </div>
        </div>
        <div className="flex justify-between border-b border-stroke-dark-12 p-4">
          <span>
            Үндсэн хураамж:
          </span>
          <span className="font-semibold">
            {formatNumber(invoiceInfo?.premium?.base_premium)}₮
          </span>
        </div>
        <div className="flex justify-between border-b border-stroke-dark-12 p-4">
          <span>
            Нэмэлт хамгаалалтын хураамж:
          </span>
          <span className="font-semibold">
            {formatNumber(invoiceInfo?.premium?.additional_premium)}₮
          </span>
        </div>
        <div className="flex justify-between border-t border-stroke-dark-12 p-4">
          <span className="font-semibold">
            Нийт дүн:
          </span>
          <span className="font-semibold text-xl">
            {formatNumber(invoiceInfo?.payment?.amount)}₮
          </span>
        </div>
      </div>
      <Typography variant="Title" size="md" className="mt-6">
        Ибаримт авах {isIndividual === "yes" ? "хүний" : "байгууллагын"} мэдээлэл
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex mt-6">
          <RadioButton
            label={"Хувь хүн"}
            checked={"yes" === isIndividual}
            value={"yes"}
            onChange={(e) => {
              setisIndividual(e.target.value);
            }}
          />
          <RadioButton
            label={"Байгууллага"}
            checked={"no" === isIndividual}
            value={"no"}
            onChange={(e) => {
              setisIndividual(e.target.value);
            }}
          />
        </div>
        <div className="mt-6 grid ">
          <div>
            {
              isIndividual === "yes" &&
              <Input
                id="invoice-mobile"
                type="number"
                label="Гар утасны дугаар"
                maxLength={8}
                {...register('mobile', {
                  maxLength: 8,
                })}
                width="w-full"
              />
            }
            {/* {errors?.mobile?.type === "required" &&
              <p className="ml-2 text-xs text-error font-medium italic pt-2">Гар утасны дугаараа оруулна уу.</p>
            } */}
          </div>
          {
            isIndividual === "no" &&
            <Input
              id="invoice-company-register"
              type="number"
              label="Байгууллагын регистерийн дугаар"
              maxLength={7}
              {...register('registrationNumber', {
                maxLength: 7,
              })}
            />
          }
        </div>
        <div className="flex justify-end">
          <Button className="mt-10" type="submit">
            Баталгаажуулах
          </Button>
        </div>
      </form>
      <Modal
        {...{ isOpenModal }}
        onClose={() => setIsOpenModal(false)}
      >
        <Payment {...{ invoiceInfo }} />
      </Modal>
    </div>
  )
}

export default StepTwo;
