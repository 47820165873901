import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useStore } from "../../../stores";

import Button from "../../../base-components/Button";
import Typography from "../../../base-components/Typography";
import MuiInput from "../../../base-components/MuiInput";

import UserService from "../../../services/UserService";
import AuthService from "../../../services/AuthService";

function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setFocus,
  } = useForm({
    defaultValues: {
      mobile: "",
      password: "",
    },
  });
  const store = window.localStorage;
  const [mobile, setMobile] = useState("");
  const setToken = useStore.useSetToken();
  const setUser = useStore.useSetUser();
  const setPrevPath = useStore.useSetPrevPath();
  const navigate = useNavigate();
  const { product_id } = useParams();

  useEffect(() => {
    setFocus("mobile", { shouldSelect: true });
  }, [setFocus]);

  const onSubmit = (data) => {
    let params = {
      username: data.mobile,
      password: data.password,
      user_type: "su",
    };
    AuthService.login(params)
      .then((response) => {
        if (response.status === "error")
          setError("mobile", {
            type: "custom",
            message: response?.message,
          });
        if (response.status === "success") {
          store.setItem("uid", response.uid);
          setToken(response.access_token);
          login();
        }
      })
      .catch((error) => {
        console.log("login-error", error);
      });
  };
  const login = () => {
    let uid = store.getItem("uid");
    UserService.me(uid)
      .then((response) => {
        if (response.status === "success") {
          setUser(response);
          setTimeout(() => {
            if (product_id && product_id !== "undefined") {
              navigate(`/insurance-process/${product_id}`);
              setPrevPath("/insure");
            } else navigate("/dashboard");
          }, 300);
        }
        if (response.status === "error") {
          switch (response.message) {
            case "User is not insured":
              useStore.setState({
                notification: { message: response.message, type: "warning" },
              });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {});
  };

  return (
    <>
      <div className="mb-8 flex">
        {/* <IconButton variant="softPrimary" onClick={(e) => { e.preventDefault() }}>
                <ArrowLeft className="w-5 h-5" />
              </IconButton> */}
      </div>
      <Typography variant="Headline" size="md">
        Нэвтрэх
      </Typography>
      <div className="mt-2 text-sm text-on-surface-black-64">
        <Typography variant="Label">
          <span className="text-surface-black/[65%]">Эрх байхгүй бол</span>
          <a
            href="/sign-in"
            className="mr-1 border-b border-primary text-primary"
          >
            {" "}
            Бүртгүүлэх
          </a>
          үү?
        </Typography>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-[333px] max-[500px]:w-full"
      >
        <div className="mt-8 space-y-3">
          <div>
            <MuiInput
              id="phoneNumber"
              label="Утасны дугаар"
              className="w-full"
              {...register("mobile", {
                required: true,
                onChange: (e) => setMobile(e.target.value),
              })}
            />
            {errors?.mobile?.type === "required" && (
              <p className="mt-1 pl-2 text-xs italic text-error">
                Утасны дугаараа оруулна уу!
              </p>
            )}
          </div>
          <div>
            <MuiInput
              id="password"
              label="Нууц үг"
              type="password"
              {...register("password", {
                required: true,
                maxLength: 50,
              })}
              className="w-full"
            />
            {errors?.password?.type === "required" && (
              <p className="mt-1 pl-2 text-xs italic text-error">
                Нууц үгээ оруулна уу!
              </p>
            )}
          </div>
          {errors.mobile?.type === "custom" && (
            <p className="mt-1 pl-2 text-xs italic text-error">
              {errors.mobile?.message}
            </p>
          )}
        </div>
        <div className="mb-2 mt-6 flex items-center justify-between space-x-14">
          <span
            className="cursor-pointer text-sm hover:underline"
            onClick={() =>
              navigate("/recover-password", {
                state: { prevPath: window.location.pathname },
              })
            }
          >
            Нууц үг сэргээх
          </span>
          <Button type="submit" disabled={mobile === ""}>
            Үргэлжлүүлэх
          </Button>
        </div>
        <div className="mt-6 flex items-center">
          <div className="w-full border-t"></div>
          <div className="m-2 w-full min-w-[112px] text-sm text-on-surface-black-64">
            Та зуучлагч уу?
          </div>
          <div className="w-full border-t"></div>
        </div>
        <Button
          variant="outlinePrimary"
          onClick={(e) => {
            navigate("/broker/login");
          }}
          className="mt-6 w-full"
        >
          Зуучлагчаар нэвтрэх
        </Button>
      </form>
    </>
  );
}

export default Login;
