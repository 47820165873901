import Service from './BaseService';

const createInvoice = (ajd, config) => Service.get(`/merch_ajd_invoice?inv=${ajd}`, config)

const insertOwnerInfo = (payload, config) => Service.post(`/merch_ajd_invoice_owner`, payload, config);

const checkPaidInvoice = (id, config) => Service.get(`/check_invoice/?policy_id=${id}`, config)

const InvoiceService = {
  createInvoice,
  insertOwnerInfo,
  checkPaidInvoice,
}

export default InvoiceService;