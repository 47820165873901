import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { useStore } from "../../../stores";
import { ReactComponent as ArrowLeft } from "../../../assets/icon/arrow-left.svg";

import Button from "../../../base-components/Button";
import Typography from "../../../base-components/Typography";
import IconButton from "../../../base-components/IconButton";
import Tabs from "../../../base-components/Tabs";
import MuiInput from "../../../base-components/MuiInput";

import UserService from "../../../services/UserService";
import AuthService from "../../../services/AuthService";

function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    setFocus,
  } = useForm({
    defaultValues: {
      mobile: "",
      password: "",
      agent: "",
    },
  });
  const store = window.localStorage;
  const [mobile, setMobile] = useState("");
  const [selectTab, setSelectTab] = useState(0);
  const setToken = useStore.useSetToken();
  const setUser = useStore.useSetUser();
  const navigate = useNavigate();

  useEffect(() => {
    setFocus("mobile", { shouldSelect: true });
  }, []);

  const onSubmit = (data) => {
    let params = {
      username: data.mobile,
      password: data.password,
      user_type: "mu",
    };
    AuthService.login(params)
      .then((response) => {
        if (response.status === "error")
          setError("mobile", {
            type: "custom",
            message: response?.message,
          });
        else if (response.status === "success") {
          store.setItem("uid", response.uid);
          setToken(response.access_token);
          login();
        }
      })
      .catch((error) => {
        console.log("login-error", error);
      });
  };
  const login = () => {
    let uid = store.getItem("uid");
    UserService.me(uid)
      .then((response) => {
        if (response.status === "success") {
          setUser(response);
          setTimeout(() => {
            navigate("/broker");
          }, 300);
        }
        if (response.status === "error") {
          switch (response.message) {
            case "User is not insured":
              console.log("Та даатгуулаагүй байна");
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {});
  };

  return (
    <>
      <div className="flex">
        <IconButton
          variant="softPrimary"
          onClick={(e) => {
            e.preventDefault();
            navigate("/login");
          }}
        >
          <ArrowLeft className="h-5 w-5" />
        </IconButton>
      </div>
      <Tabs
        className="my-8"
        value={selectTab}
        onChange={(value) => {
          setSelectTab(value);
          console.log(value, "value");
        }}
      >
        <Tabs.Tab label="Брокер" className="w-1/2" />
        <Tabs.Tab label="Төлөөлөгч" className="w-1/2" />
      </Tabs>
      <Typography variant="Headline" size="md">
        Нэвтрэх
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-[333px] max-[500px]:w-full"
      >
        {/* <div className="text-sm text-on-surface-black-64 mt-2">
              <Typography variant="Label">
                <span className="text-surface-black/[65%]">
                  Эрх байхгүй бол
                </span>
                <a href="/sign-in" className="border-b border-primary mr-1 text-primary"> Бүртгүүлэх</a>үү?
              </Typography>
            </div> */}
        {selectTab === 0 ? (
          <div className="mt-8 space-y-3">
            <div>
              <MuiInput
                id="phoneNumber"
                label="Утасны дугаар"
                className="w-full"
                {...register("mobile", {
                  required: true,
                  // maxLength: 8,
                  // pattern: /^[7-9]{1}?[0-9]{7}$/,
                  onChange: (e) => setMobile(e.target.value),
                })}
              />
              {errors?.mobile?.type === "required" && (
                <p className="mt-1 pl-2 text-xs italic text-error">
                  Утасны дугаараа оруулна уу!
                </p>
              )}
            </div>
            <div>
              <MuiInput
                id="password"
                label="Нууц үг"
                type="password"
                {...register("password", {
                  required: true,
                  maxLength: 50,
                })}
                className="w-full"
              />
              {errors?.password?.type === "required" && (
                <p className="mt-1 pl-2 text-xs italic text-error">
                  Нууц үгээ оруулна уу!
                </p>
              )}
            </div>
            {errors.mobile?.type === "custom" && (
              <p className="mt-1 pl-2 text-xs italic text-error">
                {errors.mobile?.message}
              </p>
            )}
          </div>
        ) : (
          <div className="mt-8">
            <MuiInput
              id="phoneNumber"
              label="Утасны дугаар"
              className="w-full"
              {...register("agent", {
                required: true,
                // maxLength: 8,
                // pattern: /^[7-9]{1}?[0-9]{7}$/,
                onChange: (e) => setMobile(e.target.value),
              })}
            />
            {errors?.agent?.type === "required" && (
              <p className="mt-1 pl-2 text-xs italic text-error">
                Утасны дугаараа оруулна уу!
              </p>
            )}
          </div>
        )}
        <div className="mb-2 mt-6 flex items-center justify-between space-x-14">
          <span
            className="cursor-pointer text-sm hover:underline"
            onClick={() =>
              navigate("/recover-password", {
                state: { prevPath: window.location.pathname },
              })
            }
          >
            Нууц үг сэргээх
          </span>
          <Button type="submit" disabled={mobile === ""}>
            Үргэлжлүүлэх
          </Button>
        </div>
      </form>
    </>
  );
}

export default Login;
