import { twMerge } from "tailwind-merge";
import IconButton from "../IconButton";
import { ReactComponent as ChevronLeft } from "../../assets/icon/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../assets/icon/chevron-right.svg";

const rowsLimit = [10, 20, 30];
function Pagination({
  className,
  rowsPerPage,
  current,
  page,
  totalPage,
  simple,
  children,
  onClickPrevPage = () => { },
  onClickNextPage = () => { },
  ...props
}) {
  return (
    <nav className={className}>
      <ul className="flex items-center w-full mr-0 space-x-2">
        {
          rowsPerPage && totalPage > 1 &&
          <div className="flex items-center mr-10 space-x-4">
            <span className="text-on-surface-black-64">Мөрөөр үзэх</span>
            <div className="flex space-x-0.5">
              {
                rowsLimit.map((value, index) =>
                  <div
                    key={index}
                    onClick={() => props.onClickRowsPerPage(value)}
                    className={twMerge([
                      "px-4 py-2 cursor-pointer rounded-full border text-primary",
                      rowsPerPage === value && "bg-primary text-white border-none"
                    ])}
                  >
                    {value}
                  </div>
                )
              }
            </div>
          </div>
        }
        {
          simple &&
          <span>{current}/{totalPage}</span>
        }
        <Pagination.Link
          disabled={current === 1}
          onClick={onClickPrevPage}
          className="border-stroke-dark-12 mr-0"
        >
          <ChevronLeft />
        </Pagination.Link>
        {children}
        <Pagination.Link
          disabled={current >= totalPage}
          onClick={onClickNextPage}
          className="border-stroke-dark-12 ml-0"
        >
          <ChevronRight />
        </Pagination.Link>
      </ul>
    </nav>
  )
}

Pagination.Link = ({ className, active, disabled, children, ...props }) => {
  return (
    <li className="flex-1 sm:flex-initial">
      <IconButton
        {...props}
        as="a"
        variant="softPrimary"
        className={twMerge([
          "bg-white border border-transparent text-on-surface-black-64",
          active && "!box font-medium border-secondary",
          className
        ])}
        disabled={disabled}
      >
        {children}
      </IconButton>
    </li>
  )
}

export default Pagination
