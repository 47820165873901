import { useEffect, useState } from "react";

import RadioButton from "../../../../base-components/RadioButton";
import Typography from "../../../../base-components/Typography";
import AlertModal from "../../../../base-components/AlertModal";
import Input from "../../../../base-components/Input";
import Button from "../../../../base-components/Button";
import Modal from "../../../../base-components/Modal";

import Qpay from "./types/Qpay";
import BankAccount from "./types/BankAccount";

import PaymentService from "../../../../services/PaymentService";
import InvoiceService from "../../../../services/InvoiceService";

import { formatNumber } from "../../../../utils/helper";

const Payment = ({ policyId, amount, qr_image, sequence }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [content, setContent] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [isIndividual, setisIndividual] = useState("yes");
  const [qpayMobile, setQpayMobile] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const payments = [
    {
      value: "Qpay-ээр төлөх",
      icon: "/payment/qpay.png",
      content: <Qpay {...{ qr_image }} />,
      title: "QR Код Уншуулах",
    },
    {
      value: "Дансаар шилжүүлэх",
      icon: "/payment/arrow-swap-rec.png",
      content: <BankAccount {...{ sequence, amount }} />,
      title: "Дансруу шилжүүлэх",
    },
  ];

  useEffect(() => {
    if (!isOpenModal) return;
    const interval = setInterval(() => {
      PaymentService.checkInvoice(policyId)
        .then((response) => {
          if (response.status === "success" && response.state === "paid") {
            setIsShowAlert(true);
            setIsOpenModal(false);
          }
        })
        .catch((error) => {
          console.log(error, "qpay aldaa");
        });
    }, 5000);
    return () => clearInterval(interval);
  }, [isOpenModal]);

  // Өөр төлөгчийн мэдээлэл илгээх function
  const dataPayerData = () => {
    const payload = {
      policy_id: policyId,
      mobile: qpayMobile,
      registrationNumber: registrationNumber,
    };
    // хувь хүн бол зөвхөн дугаар авна байгууллага бол регистер авна
    if (isIndividual === "yes") delete payload.registrationNumber;
    else delete payload.mobile;

    let config = {
      baseURL: `https://api.agula.mn`,
    };
    InvoiceService.insertOwnerInfo(payload, config)
      .then((response) => {
        console.log(response, "insertOwnerInfo response!");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Төлбөр төлөх button дарах үед ажиллах function
  const selectPaymentType = (data) => {
    setContent(data.content);
    setIsOpenModal(true);
    setModalTitle(data.title);
    if (qpayMobile || registrationNumber) dataPayerData();
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const btnClick = () => {
    setIsShowAlert(false);
    window.location.reload();
  };

  return (
    <>
      <div className="text-primary">
        <Typography variant="Label" size="lg">
          Нийт дүн:
        </Typography>
        <Typography variant="Title" size="lg" className="mt-5">
          {formatNumber(amount)} ₮
        </Typography>
        <Typography variant="Label" size="md" className="mt-5">
          Ибаримт авах {isIndividual === "yes" ? "хүний" : "байгууллагын"}{" "}
          мэдээлэл
        </Typography>
        <div className="mt-6 flex">
          <RadioButton
            id="payment_person_radio_button"
            label={
              <Typography variant="Body" size="lg">
                Хувь хүн:
              </Typography>
            }
            checked={"yes" === isIndividual}
            value={"yes"}
            onChange={(e) => {
              setisIndividual(e.target.value);
            }}
          />
          <RadioButton
            id="payment_copmany_radio_button"
            label={
              <Typography variant="Body" size="lg">
                Байгууллага:
              </Typography>
            }
            checked={"no" === isIndividual}
            value={"no"}
            onChange={(e) => {
              setisIndividual(e.target.value);
            }}
          />
        </div>
        <div className="mt-6 grid ">
          <div>
            {isIndividual === "yes" && (
              <Input
                width="w-full"
                id="qpay_mobile"
                label="Гар утасны дугаар"
                maxLength={8}
                type="number"
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length == 8) setQpayMobile(value);
                  else setQpayMobile("");
                }}
              />
            )}
          </div>
          {isIndividual === "no" && (
            <Input
              width="w-full"
              type="number"
              id="registrationNumber"
              label="Байгууллагын регистерийн дугаар"
              maxLength={7}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length == 7) setRegistrationNumber(value);
                else setRegistrationNumber("");
              }}
            />
          )}
        </div>
        <div className="mt-4 flex items-center gap-5 max-[500px]:flex-col">
          {payments.map((data, index) => (
            <div
              key={index}
              className="w-52 cursor-pointer rounded-xl border border-stroke-dark-12 bg-surface-gray p-4"
              onClick={() => selectPaymentType(data)}
            >
              <img src={data.icon} alt="qpay" className="h-10 w-10" />
              <Typography variant="Title" size="md" className="mt-12">
                {data.value}
              </Typography>
            </div>
          ))}
        </div>
      </div>
      <Modal
        {...{ isOpenModal }}
        title={modalTitle}
        onClose={closeModal}
        size="lg"
      >
        {content}
      </Modal>
      <AlertModal
        isOpen={isShowAlert}
        type="success"
        title="Төлбөр амжилттай төлөгдлөө"
        message="Танд баярлалаа! Таны цахим гэрээ манай цахим бүртгэлийн системд нэмэгдлээ"
        footer={
          <Button type="button" onClick={btnClick}>
            Үргэлжлүүлэх
          </Button>
        }
      />
    </>
  );
};

export default Payment;
