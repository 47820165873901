import { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../../../base-components/Button";
import Input from "../../../../base-components/Input";
import Typography from "../../../../base-components/Typography";
import InvoiceService from "../../../../services/InvoiceService";

function StepOne(props) {
  const { currentStep, setCurrentStep, setInvoiceInfo } = props;
  const { register, formState: { errors }, handleSubmit, setError } = useForm({
    defaultValues: {
      ajd: ''
    }
  })

  const onSubmit = (data) => {
    let ajd = data.ajd;
    let config = {
      baseURL: `https://api.agula.mn`,
    }
    InvoiceService.createInvoice(ajd, config)
      .then((response) => {
        if (response.status === "success") {
          setInvoiceInfo({ ...response });
          setCurrentStep(currentStep + 1);
        }
        if (response.status === "error") {
          setError("ajd", {
            type: "custom",
            message: response.message
          })
        }
      })
      .catch((error) => {
        console.log(error, "error");
      })

  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-7 w-2/6 max-[1055px]:w-max">
      <Typography variant="Headline" size="sm">Нэхэмжлэл төлөх</Typography>
      <Typography variant="Title" size="md" className="mt-8">
        Нэхэмжлэлийн дугаараа оруулна уу?
      </Typography>
      <Input
        className="!p-10 !py-12 mt-4 font-semibold text-xl"
        {...register('ajd', {
          required: true,
          // maxLength: 15
        })}
      />
      {errors?.ajd?.type === "required" &&
        <p className="text-sm text-error font-medium pt-2 ml-2">Нэхэмжлэлийн дугаараа оруулна уу.</p>
      }
      {errors?.ajd?.type === "custom" &&
        <p className="text-sm text-error font-medium pt-2 ml-2">{errors?.ajd?.message}</p>
      }
      <div className="flex justify-end">
        <Button type="submit" className="mt-10">
          Үргэлжлүүлэх
        </Button>
      </div>
    </form>
  )
}

export default StepOne;