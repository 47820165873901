import { Fragment, forwardRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Transition } from "@headlessui/react";
import { ClickAwayListener } from "@mui/material";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { ReactComponent as CalendarIcon } from "../../assets/icon/calendar.svg";
import { isNullOrUndefined } from "../../utils/helper";
import Calendar from "../../base-components/Calendar";

const Date = forwardRef((props, ref) => {
  const {
    disabled = false,
    id = "",
    label,
    date = dayjs(),
    className,
    placement = "left",
    onChangeDate = () => { },
    minDate,
    maxDate
  } = props;
  const [isShow, setIsShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState(date);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    setIsShow(!isShow);
    // props.handleClick(e);
  }
  const handleChangeDate = (date) => {
    onChangeDate(date);
    setSelectedDate(date);
    setIsShow(false);
  }

  return (
    <ClickAwayListener onClickAway={() => setIsShow(false)} >
      <div className='relative cursor-pointer w-full' >
        <label
          className={twMerge([
            'absolute top-0 z-10 ml-1 translate-x-12 translate-y-4 text-sm text-on-surface-black-64 transition-all duration-300 pointer-events-none',
            !isNullOrUndefined(date) && 'translate-y-1 text-xs',
            // disabled && 'text-on-surface-black-12 opacity-30',
            // isShow && 'border-primary',
          ])}
          htmlFor={id}
        >
          {label}
        </label>
        <button
          id={id}
          onClick={handleClick}
          // onBlur={(e) => { e.stopPropagation(); setIsShow(false); }}
          className={twMerge([
            'transition duration-200 ease-in-out w-full text-sm text-primary border-stroke-dark-12 border p-4 shadow-sm rounded-lg font-medium tracking-wide outline-none bg-white',
            isShow && 'border-on-surface-black-64',
            className,
          ])}>
          <div className='flex'>
            <CalendarIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <span className={twMerge(["block truncate mx-4", disabled && "text-on-surface-black-64"])}>
              {date.format("YYYY/MM/DD") || selectedDate.format("YYYY/MM/DD")}
            </span>
          </div>
        </button>
        <Transition
          as={Fragment}
          show={isShow}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <div
            {...props}
            className={
              twMerge([
                'absolute w-full mt-2 z-50 rounded-lg animation-all overflow-y-auto overflow-x-hidden w-max',
                placement === "left" && "left-0",
                placement === "right" && "right-0",
              ])}
          >
            <Calendar
              value={selectedDate}
              onChange={handleChangeDate}
              minDate={minDate}
              maxDate={maxDate}
            />
          </div>
        </Transition>
      </div >
    </ClickAwayListener>
  )
})

Date.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  className: PropTypes.string,
  placement: PropTypes.string,
  onChangeDate: PropTypes.func,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date)
}

// Date.defaultProps = {
// date: null,
// minDate: null,
// maxDate: null,
// };

export default Date;