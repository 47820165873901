import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Controller, useFormContext } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import dayjs from "dayjs";

import Date from "../../../../components/DatePicker";
import Typography from "../../../../base-components/Typography";
import SwitchControl from "../../../../base-components/Switch";
import Select from "../../../../base-components/Select";
import Checkbox from "../../../../base-components/Checkbox";
import MuiInput from "../../../../base-components/MuiInput";

import InfoService from "../../../../services/InfoService";
import DynamicFieldService from "../../../../services/DynamicFieldService";

import { ReactComponent as ImageUpload } from "../../../../assets/icon/image-upload-1.svg";
import { ReactComponent as AlertIcon } from "../../../../assets/icon/alert-circle-1.svg";
import { formatNumber } from "../../../../utils/helper";
import { useStore } from "../../../../stores";
import Button from "../../../../base-components/Button";

const MzField = ({
  gettingPrice,
  title,
  sequence,
  currentStep,
  setCurrentStep,
  steps,
}) => {
  const {
    setValue,
    watch,
    getValues,
    trigger,
    formState: { isValid },
  } = useFormContext();
  const [mzFields, setMzFields] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    DynamicFieldService.getMzField(id)
      .then((response) => {
        if (response.status === "Success") {
          let filterList = response.values.filter(
            (x) => x.group_name === title,
          );
          setMzFields([...filterList]);
        }
      })
      .catch((error) => {
        console.log(error, "mz ahiin talbaruudiig avahad aldaa garlaaa!!!!");
      });
  }, [id]);

  const saveDynamicColumn = () => {
    gettingPrice();
    if (!isValid) return trigger();
    let dynamic_column = getValues("dynamic_column");
    if (currentStep === steps.length - 1) setValue("showContinue", false);
    setValue("dynamic_column", { ...dynamic_column });
    if (currentStep < steps.length - 1) setCurrentStep(currentStep + 1);
  };

  return (
    <div className="mt-10">
      <Typography variant="Headline" size="sm">
        {title}
      </Typography>
      <div className={"mt-10 grid grid-cols-6 gap-4"}>
        {mzFields.length > 0 &&
          mzFields.map((field, index) => (
            <div
              key={index}
              className={twMerge([
                "col-span-2 max-[841px]:col-span-3 max-[500px]:col-span-6",
                field.ttype === "text" &&
                  field.name.match("_img") &&
                  "max-[570px]:col-span-6",
              ])}
            >
              {field.ttype === "date" && <DateField {...{ field }} />}
              {field.ttype === "boolean" && <BooleanField {...{ field }} />}
              {(field.ttype === "many2one" || field.ttype === "selection") && (
                <SelectionField
                  talbar={field}
                  handleSelectedField={saveDynamicColumn}
                />
              )}
              {field.ttype === "text" && field.name.match("_img") ? (
                <UploadFile {...{ field }} />
              ) : (
                (field.ttype === "integer" ||
                  field.ttype === "char" ||
                  field.ttype === "text" ||
                  field.ttype === "float") && <InputField {...{ field }} />
              )}
              {field.ttype === "binary" && <UploadFile {...{ field }} />}
            </div>
          ))}
        {/* {
          // currentStep < steps.length - 1 &&
          watch("showContinue") && currentStep === sequence && (
            <div className="col-span-6">
              <div className="flex justify-end">
                <Button type="button" onClick={saveDynamicColumn}>
                  Үргэлжлүүлэх
                </Button>
              </div>
            </div>
          )
        } */}
      </div>
    </div>
  );
};

const DateField = ({ field }) => {
  const { setValue } = useFormContext();
  const [date, setDate] = useState(dayjs());

  useEffect(() => {
    setValue(
      `dynamic_column.${field.name}`,
      dayjs().format("YYYY-MM-DD HH:mm:ss"),
    );
  }, [field]);

  const handleChangeDate = (value) => {
    setDate(value);
    setValue(
      `dynamic_column.${field.name}`,
      value.format("YYYY-MM-DD HH:mm:ss"),
    );
  };

  return (
    <div>
      <Date
        id={`mz_field_${field.name}`}
        label={field.description}
        date={date}
        onChangeDate={handleChangeDate}
      />
    </div>
  );
};

const BooleanField = ({ field }) => {
  const { setValue } = useFormContext();
  const [checked, setChecked] = useState(false);

  const handleChange = (checked) => {
    setChecked(checked);
    let value = checked ? 1 : 0;
    setValue(`dynamic_column.${field.name}`, value);
  };

  return (
    <div className="flex items-center space-x-4 rounded-lg border bg-white p-3">
      <Typography variant="Label" size="lg" className="w-[260px]">
        {field?.description}
      </Typography>
      <SwitchControl
        id={`mz_field_${field.name}`}
        checked={checked}
        onChange={handleChange}
      />
    </div>
  );
};

const InputField = ({ field, ...props }) => {
  const {
    setValue,
    register,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (field.ttype === "integer" || field.ttype === "float")
      setValue(`dynamic_column.${field?.name}`, 0);
  }, [field]);

  return (
    <>
      <MuiInput
        {...props}
        id={field.name}
        label={field?.description}
        className="w-full"
        {...register(`dynamic_column.${field?.name}`, {
          required: field.required,
          onChange: (e) => {
            if (field.ttype === "integer" || field.ttype === "float")
              setValue(
                `dynamic_column.${field?.name}`,
                formatNumber(e.target.value, 0),
              );
            else setValue(`dynamic_column.${field?.name}`, e.target.value);
          },
        })}
      />
      {errors?.dynamic_column &&
        errors.dynamic_column[field.name]?.type === "required" && (
          <p className="mt-1 pl-2 text-xs text-error">{`${field.description} оруулна уу`}</p>
        )}
    </>
  );
};

const SelectionField = ({ talbar, handleSelectedField }) => {
  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setValue(`dynamic_column.${talbar?.name}`, "");
  }, [talbar]);

  const onSelectValue = (option) => {
    if (talbar.ttype === "selection")
      setValue(`dynamic_column.${talbar.name}`, option.value);
    else setValue(`dynamic_column.${talbar.name}`, option.id);

    handleSelectedField();
  };

  return (
    <Controller
      control={control}
      name={`dynamic_column.${talbar.name}`}
      render={({ field: { value, onChange, onBlur } }) => (
        <>
          <Select
            id={`mz_field_${talbar.name}`}
            label={talbar.description}
            value={value}
            onChange={(option) => {
              onChange(option);
              onSelectValue(option);
            }}
            options={options}
            icon={talbar.ttype === "many2many" ? <Checkbox /> : undefined}
            service={
              talbar.relation === ""
                ? InfoService.getIrModelFields
                : InfoService.getRelationInfo
            }
            params={talbar.relation === "" ? talbar.id : talbar.relation}
          />
          {errors?.dynamic_column &&
            errors.dynamic_column[talbar.name]?.type === "required" && (
              <p className="mt-1 pl-2 text-xs text-error">{`${talbar.description} сонгоно уу`}</p>
            )}
        </>
      )}
      rules={{ required: talbar.required }}
    />
  );
};

const UploadFile = ({ field }) => {
  const {
    setValue,
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  useEffect(() => {
    setValue(`dynamic_column.${field?.name}`, "");
  }, [field]);

  const imageMimeType = /image\/(jpg|jpeg)/i;

  const uploadToImage = (e, id) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];

      const size = file.size;
      const fileMb = size / 1024 ** 2;
      if (fileMb > 10)
        return useStore.setState({
          notification: {
            message: "10mb-аас бага хэмжээтэй зураг оруулна уу.",
            type: "warning",
          },
        });
      if (!file?.type.match(imageMimeType))
        return useStore.setState({
          notification: {
            message:
              "Зөвхөн jpg, jpeg өргөтгөлтэй зураг оруулна уу./jpg, jpeg/",
            type: "warning",
          },
        });

      let fileReader,
        isCancel = false;
      if (file) {
        fileReader = new FileReader();

        fileReader.onload = (e) => {
          const { result } = e.target;
          if (result && !isCancel) setValue(`dynamic_column.${id}`, result);
          // setSelectedFile(result)
        };
        fileReader.readAsDataURL(file);
      }
    }
  };
  return (
    <Controller
      control={control}
      name={`dynamic_column.${field.name}`}
      render={({ field: { value, onChange, onBlur } }) => (
        <>
          <label htmlFor={field.name}>
            <div
              id="img-wrapper"
              className="relative flex h-60 w-60 cursor-pointer items-center justify-center rounded-xl border border-dashed bg-surface-gray p-4"
            >
              {value ? (
                <div className="absolute inset-0 flex items-center justify-center">
                  <img
                    src={value}
                    alt="upload"
                    className={`max-h-[14.8rem] w-max`}
                  />
                </div>
              ) : (
                <div className="w-full py-6">
                  <div className="absolute right-0 top-0 flex h-10 w-10 items-center justify-center">
                    <AlertIcon />
                  </div>
                  <div className="flex justify-center text-secondary">
                    <ImageUpload fill="#bbc1c8" className="h-10 w-10" />
                  </div>
                  <div className="-mb-8 space-y-1 p-4 text-center">
                    <Typography variant="Title" size="md">
                      {field?.description} оруулна уу?
                    </Typography>
                    <Typography size="md">Файлын дээд хэмжээ (10mb)</Typography>
                  </div>
                </div>
              )}
            </div>
          </label>
          <input
            id={field.name}
            type="file"
            accept=".jpg, .jpeg, .heic"
            className="hidden"
            onChange={(e) => {
              onChange(e);
              uploadToImage(e, field.name);
            }}
          />
          {errors?.dynamic_column &&
            errors.dynamic_column[field.name]?.type === "required" && (
              <p className="mt-1 pl-2 text-xs text-error">{`${field.description} оруулна уу!`}</p>
            )}
        </>
      )}
      rules={{ required: field.required }}
    ></Controller>
  );
};

export default MzField;
