import { forwardRef, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { isNullOrUndefined } from "../../utils/helper";

const Input = forwardRef((props, ref) => {
  const {
    id,
    value,
    width,
    label,
    className,
    disabled,
    focus = false,
    onBlur = () => { }
  } = props;
  const [isFocus, setIsFocus] = useState(focus);

  useEffect(() => {
    if (isNullOrUndefined(document.getElementById(id)?.value) || document.getElementById(id)?.value === "") return;
    setIsFocus(true);
  }, [])

  const onFocus = () => {
    setIsFocus(true);
  }
  const handleBlur = (e) => {
    if (document.getElementById(id)?.value === "")
      setIsFocus(false);
    if (!isNullOrUndefined(document.getElementById(id)?.value))
      return;
    setIsFocus(false);
    onBlur(e);
  }

  return (
    <div className={twMerge([
      'relative',
      width,
      // !value && 'flex items-center'
    ])}>
      <label
        className={twMerge([
          'absolute top-0 left-0 z-10 translate-x-4 translate-y-4 text-sm text-on-surface-black-64 bg-white transition-all duration-300 pointer-events-none',
          disabled && 'text-stroke-dark-12 opacity-30',
          (isFocus || value) && ' translate-y-1 text-xs bg-transparent',
        ])}
        htmlFor={id}
      >
        {label}
      </label>
      <input
        {...props}
        ref={ref}
        id={id}
        value={value}
        className={twMerge([
          'transition duration-200 ease-in-out w-full text-sm text-primary border-stroke-dark-12 border px-4 py-4 shadow-sm rounded-lg font-medium tracking-wide outline-none spin-button-none',
          'placeholder:text-on-surface-black-24', // Placeholder
          'active:border-on-surface-dark-64', // Active
          'focus:border-stroke-dark-12 focus:border-opacity-40', // Focus
          'disabled:opacity-30 disabled:cursor-not-allowed', // Disabled
          label && 'pt-5 pb-3',
          className
        ])}
        onFocus={onFocus}
        onBlur={handleBlur}
      />
    </div>
  )
});

export default Input;