import { useEffect, useState } from "react";
import AlertModal from "../../../../base-components/AlertModal";
import Button from "../../../../base-components/Button";
import Modal from "../../../../base-components/Modal";
import BankAccount from "./types/BankAccount";
import Qpay from "./types/Qpay";
import InvoiceService from "../../../../services/InvoiceService";
import Ebarimt from "./ebarimt";
import { formatNumber } from "../../../../utils/helper";

function Payment(props) {
  const { invoiceInfo } = props;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [content, setContent] = useState();
  const [modalTitle, setModalTitle] = useState();
  const [isShowAlert, setIsShowAlert] = useState(false);

  const payments = [
    {
      value: "Qpay-ээр төлөх",
      icon: "/payment/qpay.png",
      content: <Qpay {...props} />,
      title: "QR Код Уншуулах"
    },
    {
      value: "Дансаар шилжүүлэх",
      icon: "/payment/arrow-swap-rec.png",
      content: <BankAccount {...props} />,
      title: "Дансруу шилжүүлэх"
    },
  ]

  useEffect(() => {
    if (!isOpenModal) return;
    const interval = setInterval(() => {
      let config = {
        baseURL: `https://api.agula.mn`,
        nonLoading: true,
      }
      InvoiceService.checkPaidInvoice(invoiceInfo?.policy_id, config)
        .then((response) => {
          if (response.state === "paid") {
            setIsOpenModal(false);
            setIsShowAlert(true);
          }
        })
        .catch((error) => {
          console.log(error, "error");
        })
    }, 10000);
    return () => clearInterval(interval);
  }, [isOpenModal])

  const selectPaymentType = (data) => {
    setContent(data.content);
    setIsOpenModal(true);
    setModalTitle(data.title);
  }
  const closeModal = () => {
    setIsOpenModal(false);
  }
  const btnClick = () => {
    setIsShowAlert(false);
    setIsOpenModal(true);
    setContent(<Ebarimt />);
    setModalTitle("И-баримт");
  }

  return (
    <>
      <div className="text-primary">
        <div>
          <div className="text-base">Нийт дүн:</div>
          <div className="text-2xl font-bold">{formatNumber(invoiceInfo?.payment?.amount)}₮</div>
        </div>
        <div className="mt-4 flex gap-5 max-[500px]:flex-col items-center">
          {
            payments.map((data, index) =>
              <div
                key={index}
                className="bg-surface-gray border border-stroke-dark-12 rounded-xl p-4 w-52 cursor-pointer"
                onClick={() => selectPaymentType(data)}
              >
                <img src={data.icon} alt="qpay" className="w-10 h-10" />
                <div className="mt-12 text-base font-semibold">{data.value}</div>
              </div>
            )
          }
        </div>
      </div>
      <Modal
        {...{ isOpenModal }}
        title={modalTitle}
        onClose={closeModal}
        size="lg"
      >
        {content}
      </Modal>
      <AlertModal
        isOpen={isShowAlert}
        type="success"
        title="Төлбөр амжилттай төлөгдлөө"
        message="Танд баярдлалаа! Таны цахим гэрээ манай цахим бүртгэлийн системд нэмэгдлээ"
        footer={
          <Button onClick={btnClick}>Үргэлжлүүлэх</Button>
        }
      />
    </>
  )
}

export default Payment;
