import { useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import { twMerge } from "tailwind-merge";
import dayjs from "dayjs";

import IconButton from "../../../base-components/IconButton";
import Typography from "../../../base-components/Typography";
import DatePicker from "../../../components/DatePicker";
import Pagination from "../../../base-components/Pagination";
import Table from "../../../base-components/Table";
import Empty from "../../../base-components/Empty";

import { ReactComponent as Filter } from "../../../assets/icon/filter.svg";
import { formatNumber, formatDateSplitTime } from "../../../utils/helper";

import PolicyInfoService from "../../../services/PolicyInfoService";
import { ReactComponent as FileDownload } from '../../../assets/icon/file-download.svg'


const Sales = () => {
  const currentDate = dayjs();
  const [sales, setSales] = useState([]);
  const [inceptionDate, setInceptionDate] = useState(currentDate);
  const [expiryDate, setExpiryDate] = useState(currentDate);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  // useEffect(() => {
  //   getSales();
  // }, [])

  useEffect(() => {
    getSales()
  }, [inceptionDate, expiryDate])

  const getSales = (rowsNumber) => {
    setLoading(true);
    let params = {
      limit: rowsNumber || rowsPerPage,
      page: page,
      filter: {
        begin_datetime: inceptionDate.format("YYYY-MM-DD 00:00:00"),
        end_datetime: expiryDate.format("YYYY-MM-DD HH:mm:ss"),
      },
      sort_field: "inception_datetime",
      sort_type: "asc"
    }
    let config = { nonLoading: true }
    PolicyInfoService.getInsurancePolicies(params, config)
      .then((response) => {
        if (response.status === "Success") {
          setSales([...response.values]);
          setTotalPage(response.total_pages);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error, "policies error");
        setLoading(false);
      })
  }

  const handleDownload = (id) => {
    if (id)
      PolicyInfoService.getInsurancePolicyFileUrl(id, {
        nonLoading: true
      }).then(response => window.open(response, '_blank'))
  }

  return (
    <div>
      <div className="flex items-center justify-between py-8 px-16 max-[841px]:px-10 max-[500px]:px-4 border-y">
        <Typography variant="Headline" size="md">Борлуулалт</Typography>
        <div className="flex items-center space-x-3">
          <DatePicker
            date={inceptionDate}
            onChangeDate={(date) => setInceptionDate(date)}
            className="rounded-xl text-base"
            placement="right"
          />
          <span className="border-y border-primary w-4"></span>
          <DatePicker
            date={expiryDate}
            onChangeDate={(date) => setExpiryDate(date)}
            className="rounded-xl text-base"
            placement="right"
          />
          <div className="pl-3 border-l">
            <IconButton variant="outlinePrimary" size="lg" onClick={() => getSales()}>
              <Filter className="w-5 h-5 text-primary" />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="py-10 px-16 max-[841px]:px-10 max-[500px]:px-4 text-primary">
        <div className={twMerge(["border rounded-xl relative overflow-x-auto", sales.length === 0 && "h-48"])}>
          {
            sales.length === 0 &&
            <div className="absolute top-14 inset-x-0">
              {
                loading ?
                  <div className="flex justify-center mt-12">
                    <PulseLoader
                      color="#BBC1C9"
                      speedMultiplier={0.7}
                      loading={loading}
                      size={12}
                    />
                  </div> :
                  <Empty className="mt-6" />
              }
            </div>
          }
          <Table bordered>
            <Table.Thead>
              <Table.Tr className={"uppercase"}>
                <Table.Th>№</Table.Th>
                <Table.Th>Гэрээний дугаар</Table.Th>
                <Table.Th>Эхлэх огноо</Table.Th>
                <Table.Th>Бүтээгдэхүүн</Table.Th>
                <Table.Th>Багц</Table.Th>
                <Table.Th>Даатгуулагч</Table.Th>
                <Table.Th>Үнэлгээ</Table.Th>
                <Table.Th>Хураамж</Table.Th>
                <Table.Th>Төлөгдсөн огноо</Table.Th>
                <Table.Th>Дуусах огноо</Table.Th>
                <Table.Th>Татах</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody className={"border-t font-light text-xs"}>
              {
                sales.length > 0 &&
                sales.map((sale, index) =>
                  <Table.Tr key={index} >
                    <Table.Td>{index + 1}</Table.Td>
                    <Table.Td>{sale.warranty_number}</Table.Td>
                    <Table.Td>{formatDateSplitTime(sale.inception_datetime)}</Table.Td>
                    <Table.Td>{sale.dynamic_product_name}</Table.Td>
                    <Table.Td>{sale.package_id[1]}</Table.Td>
                    <Table.Td>{sale.partner_name}</Table.Td>
                    <Table.Td className={"whitespace-nowrap"}>{formatNumber(sale?.market_price, 0)} ₮</Table.Td>
                    <Table.Td className={"whitespace-nowrap"}>{formatNumber(sale.insurance_fee, 0)} ₮</Table.Td>
                    <Table.Td>
                              {
                                sale?.paid_date
                                  ? formatDateSplitTime(sale.paid_date)
                                  : <span style={{ color: sale?.payment_state == 'paid' ? 'green' : '#B7281E' }}>{sale?.payment_state == "paid" ? 'Төлөгдсөн' : 'Төлөгдөөгүй'}</span>
                              }
                    </Table.Td>
                    <Table.Td>{formatDateSplitTime(sale.expiry_datetime)}</Table.Td>
                    <Table.Td>
                      <IconButton size='sm' variant='softPrimary'>
                        <FileDownload
                          stroke='#0A213A'
                          className='w-4 h-4'
                          onClick={() => handleDownload(sale.id)}
                        />
                      </IconButton>
                    </Table.Td>
                  </Table.Tr>
                )
              }
            </Table.Tbody>
          </Table>
        </div>
        {/* <div className="px-3 py-2.5 truncate">Төлөгдсөн огноо</div> */}
        <div className="flex justify-end items-center mt-4">
          {/* <div className="space-x-4">
            <span className="text-on-surface-black-64">Нийт хураамж:</span>
            <span>103,408,000.00₮</span>
          </div>
          <div className="space-x-4">
            <span className="text-on-surface-black-64">Нийт борлуулсан гэрээ:</span>
            <span>128</span>
          </div> */}
          {
            sales.length > 0 &&
            <div className="flex items-center text-xs">
              <Pagination
                simple
                current={page}
                totalPage={totalPage}
                rowsPerPage={rowsPerPage}
                onClickRowsPerPage={(rowsNumber) => {
                  setRowsPerPage(rowsNumber);
                  getSales(rowsNumber);
                }}>
              </Pagination>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default Sales;
