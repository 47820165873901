import { forwardRef } from "react";

const RadioButton = forwardRef((props, ref) => {
  const {
    label,
    checked = false,
    id = checked,
    onChange,
  } = props;

  return (
    <div className="flex items-center ml-8 first:ml-0 text-primary">
      <input
        {...props}
        ref={ref}
        checked={checked}
        onChange={onChange}
        type="radio"
        className="w-4 h-4 accent-primary cursor-pointer"
        id={`radio-button-${id}`}
      />
      <label
        className="pl-4 hover:cursor-pointer w-full"
        htmlFor={`radio-button-${id}`}
      >
        {label}
      </label>
    </div>
  )
})

export default RadioButton;