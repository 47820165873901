import React from "react"
import { twMerge } from "tailwind-merge"
import PropTypes from "prop-types";

function Table({ className, dark, bordered, hover, striped, sm, ...props }) {
  // const renderChildren = () => {
  //   return React.Children.map(props.children, (child) => {
  //     return React.cloneElement(child, {
  //       bordered: bordered,
  //     })
  //   })
  // }

  return (
    <table
      className={twMerge([
        "w-full text-left text-sm border-spacing-0 border-collapse border-hidden",
        dark && "bg-dark text-white dark:bg-primary",
        className
      ])}
      {...props}
    >
      {props.children}
    </table>
  )
}

Table.Thead = ({ className, ...props }) => {
  return (
    <thead
      className={twMerge([
        "bg-surface-gray",
        className
      ])}
      {...props}
    >
      {props.children}
    </thead>
  )
}

Table.Tbody = ({ className, ...props }) => {
  return <tbody className={className}>{props.children}</tbody>
}

Table.Tr = ({ className, ...props }) => {
  return (
    <tr
      className={twMerge([
        // table.hover &&
        // "[&:hover_td]:bg-slate-100 [&:hover_td]:dark:bg-darkmode-300 [&:hover_td]:dark:bg-opacity-50",
        // table.striped &&
        // "[&:nth-of-type(odd)_td]:bg-slate-100 [&:nth-of-type(odd)_td]:dark:bg-darkmode-300 [&:nth-of-type(odd)_td]:dark:bg-opacity-50",
        className
      ])}
      {...props}
    >
      {props.children}
    </tr>
  )
}

Table.Th = ({ className, ...props }) => {
  return (
    <th
      className={twMerge([
        "font-medium px-5 px-3 py-2.5 border-b dark:border-darkmode-300",
        "first:rounded-tl-xl last:rounded-tr-xl",
        // table.dark && "border-slate-600 dark:border-darkmode-300",
        // table.sm && "px-4 py-2",
        className
      ])}
      {...props}
    >
      {props.children}
    </th>
  )
}

Table.Td = ({ className, ...props }) => {
  return (
    <td
      className={twMerge([
        "px-5 py-3 border-b dark:border-darkmode-300",
        // table.dark && "border-slate-600 dark:border-darkmode-300",
        // table.bordered && "border-l border-r border-t",
        // table.sm && "px-4 py-2",
        className
      ])}
      {...props}
    >
      {props.children}
    </td>
  )
}

Table.propTypes = {
  bordered: PropTypes.bool,
}

export default Table
